<template>
	<div>
		<access-terms></access-terms>
	</div>
</template>

<script>
import AccessTerms from '../components/AccessTerms.vue';

export default {
	components: { AccessTerms },
};
</script>

<style></style>
