<template>
	<div id="Back_color">
		<header>
			<h1>FOUR-CHAINS 이용약관</h1>
		</header>
		<!--content1-->
		<div id="container">
			<section class="content1">
				<h3>제1장 일반 조건</h3>
				<h5>제1조 (목적)</h5>
				<p>
					본 서비스 약관(이하 “약관”)은 ㈜포체인스 서비스 웹사이트<a href="#"
						>(http://fc-ts.com)</a
					>에 접근 또는 이용하거나, 혹은 그 서비스(아래 정의)를 이용하는
					사용자의 (이하 “개인회원”) 권리와 의무 및 기타 이에 관련한 사항을
					명시하는 것을 목적으로 합니다.
				</p>

				<h5>제2조 (용어 정의)</h5>
				<p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>

				<ul>
					<li>
						“개인회원”은 본 약관에 따른 서비스 이용계약을 체결하고 ㈜포체인스가
						제공하는 서비스를 이용하는 기업의 형태가 아닌 개인의 회원을
						의미합니다.
					</li>
					<li>
						"FCTS (FOUR CHAINS TECHNOLOGY SERVICE)"이란 “포체인스”에서 제공하는
						개별적 서비스를 말하며 다음 각 항목의 서비스를 제공합니다.
					</li>
					<ul class="color_red">
						<li>AI</li>
						<li>Block Chain</li>
						<li>Cryptography</li>
						<li>Quant Anaysis</li>
					</ul>
					<li>
						“개인회원”이란 본 약관에 따라 “포체인스”와 이용계약을 체결하고,
						“포체인스”가 제공하는 서비스를 이용하는 개인을 칭합니다.
					</li>
					<li>
						개인회원의 정보”라 함은 “개인회원”이 “포체인스”의 서비스에 저장하는
						정보(『국가정보화기본법』 제 3조 제1호에 따른 정보, 개인일 경우에는
						개인정보와 신용정보를 포함합니다) 로써 고객이 소유 또는 관리하는
						정보를 의미합니다.
					</li>
				</ul>

				<h5>제3조 (약관의 게시 및 개정)</h5>

				<ul>
					<li>
						약관은 “포체인스”의 서비스 웹사이트<a href="#">(http://fc-ts.com)</a
						>에서 확인 가능합니다. 약관은 “개인회원”이 “포체인스”의 서비스
						웹사이트에 접속 또는 이용할 때 그리고 서비스 계약 성립에 따라
						제공되는 서비스에 적용됩니다. “개인회원”이 포체인스 서비스
						웹사이트에 접속 또는 이용하기 위해서는 약관에 동의해야 합니다.
					</li>
					<li>
						포체인스”는 ‘약관의 규제에 관한 법률’, ‘정보통신망 이용촉진 및
						정보보호 등에 관한 법률’, ‘클라우드 컴퓨팅 발전 및 이용자 보호에
						관한 ‘법률’ 등 관련 법을 위배하지 않는 범위에서 본 약관을 개정할 수
						있습니다.
					</li>
					<li>
						포체인스”는 “포체인스”의 서비스 웹사이트 내 수정 약관의 게시 등
						합리적인 방법을 통해 약관을 수정할 수 있습니다. 이때 “포체인스”는
						약관 수정 사항의 효력이 발생하기 최소 7일 전에 효력 발생일과 자세한
						내용을 명시하여 “포체인스” 서비스 웹사이트를 통해 이를 공지합니다.
						단, 수정 사항이 고객의 권리 및 의무와 관련해 불리하게 작용하는 경우
						“포체인스”는 제 7조 제1항에 명시된 바에 따라 효력 발생일 최소 30일
						이전에 해당 고객에게 이를 통지합니다.
					</li>
					<li>
						포체인스”가 전항에 따라 개정약관을 공지 또는 통지하면서
						“개인회원”에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가
						표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도
						“개인회원”이 명시적으로 거부의 의사표시를 하지 아니한 경우,
						“개인회원”이 개정약관에 동의한 것으로 봅니다.
					</li>
					<li>
						개인회원”이 개정약관의 적용에 동의하지 않는 경우 “포체인스” 또는
						“개인회원”은 이용계약을 해지할 수 있습니다. 이 경우 “포체인스”는
						“개인회원”에게 서면, 전자우편 또는 이에 따르는 방법으로 해지 사유,
						해지일, 환급 비용을 통지합니다.
					</li>
				</ul>

				<h5>제4조 (약관 외 준칙)</h5>
				<ul>
					<li>
						본 약관에서 정하지 아니한 사항은 준거법, 규정, 상관습 및 서비스
						웹사이트 내 개별서비스 운영정책(해당 시)에 따릅니다. 오해의 소지를
						피하고자 부연하자면, “포체인스”는 각각의 개별서비스에 대한 별도
						운영정책을 시행할 수 있습니다.
					</li>
					<li>
						“포체인스”는 개별서비스에 적용될 사항을 정하여 개별약관으로 운영할
						수 있습니다. 개별약관의 내용이 본 약관과 상충할 경우 별도로 정한
						바가 없다면 개별약관이 우선 적용됩니다.
					</li>
					<li>개별약관에 규정이 없는 경우 본 약관에 따릅니다.</li>
				</ul>
			</section>
			<!--content1 end-->
			<!--content2-->
			<section class="content2">
				<h3>제 2장 서비스 이용</h3>
				<h5>제5조 (서비스 이용의 신청)</h5>
				<ul>
					<li>
						서비스 이용 신청자 “개인회원”이 약관에 동의한 후 서비스 이용을
						신청하고, “포체인스”가 이에 승인함으로써 서비스 이용계약(“서비스
						계약”)이 성립합니다.
					</li>
					<li>
						서비스 이용 신청 시 “개인회원”은 실명과 실제 정보를 제공해야 합니다.
					</li>
					<li>
						“개인회원”이 서비스 신청 시 제공한 개인정보는 준거법 및 규정,
						“포체인스”의 개인정보보호 정책에 따라 보호됩니다.
					</li>
				</ul>

				<h5>제6조 (서비스 계약의 성립)</h5>
				<ul>
					<li>
						“개인회원”이 상기 제 5조에서 명시한 정보를 성실하고 정확하게
						작성하면 “포체인스”는 특별한 사유가 없는 한 신청을 승인합니다.
					</li>
					<li>
						신청을 받은 이후 다음과 같은 사항에 해당하는 경우, “포체인스”는
						서비스 이용 신청 승인을 거부하거나 연기할 수 있으며 또는 서비스
						계약을 해지할 수 있습니다.
					</li>
					<ul class="color_red">
						<li>신청인이 실명을 사용하지 않았거나 타인의 명의를 이용한 경우</li>
						<li>
							신청인이 거짓 정보를 제공하거나 “포체인스”가 요구하는 정보를
							제출하지 않은 경우
						</li>
						<li>
							신청인이 법률 위반 또는 불법 행위 등 부정한 용도로 서비스를
							이용하려 하는 경우
						</li>
						<li>
							신청인의 귀책 사유로 인해 신청의 승인이 불가하거나, 신청이
							“포체인스”의 정책에 위반하는 경우
						</li>
						<li>
							그 밖에 제1호에서 제4호까지에 준하는 사유로써, 승낙하는 것이
							상당히 부적절하다고 판단되는 경우
						</li>
					</ul>

					<li>
						“포체인스”가 서비스 이용 신청을 거부하거나 연기할 것을 결정한 경우
						신청인에게 이를 통지합니다.
					</li>
				</ul>

				<h5>제7조 (고객에 대한 통지)</h5>

				<ul>
					<li>
						“포체인스”가 “개인회원”에게 통지해야 하는 경우, “포체인스”는
						약관에서 별도로 명시하지 않는 한 서비스 이용 신청 시 “개인회원”이
						등록한 이메일주소를 이용하여 통지합니다.
					</li>
					<li>
						“개인회원” 전체에 통지해야 하는 경우, “포체인스”는 상기 제1항에서
						명시한 방식 대신 서비스 웹사이트에 7일 이상 공지함으로써 통지를
						대신할 수 있습니다. 단, “개인회원”의 권리 및 의무와 관련해 불리한
						변경 사항을 통지하는 경우에는 본 조항이 적용되지 않습니다.
					</li>
					<li>
						“개인회원”은 “포체인스”의 통지 사항을 수신할 수 있도록 연락처
						정보(이메일주소, 휴대전화 번호, 유선전화번호 등)를 항상 최신 상태로
						유지할 의무가 있습니다. 연락처 정보가 업데이트되어 있지 않아
						“개인회원”에게 발생하는 불이익에 대해 “포체인스”는 어떠한 책임도
						지지 않습니다.
					</li>
				</ul>

				<h5>제8조 (개별서비스의 유형 및 변경)</h5>

				<ul>
					<li>
						“포체인스”는 개별서비스의 종류, 세부 사항 및 가격뿐만 아니라
						개별서비스의 이용약관, 개별서비스, 운영 규칙 등을 포함한 세부 사항을
						서비스 웹사이트에 게시합니다. “개인회원”은 서비스 웹사이트에 게재된
						세부 사항을 충분히 숙지하고 관련 정보를 바탕으로 서비스를 신청해야
						하며 “기업회원”으로 전환 및 변경 시 필요한 사항에 대해선
						“기업회원”솔루션 구매 신청서를 작성 회신을 주어야 솔루션 이용이
						가능합니다.
					</li>
				</ul>

				<h5>제9조 (서비스 제공의 의무)</h5>

				<ul>
					<li>
						원칙적으로 “포체인스”는 하루 6시간, 주 5일의 서비스를 제공합니다.
						단, 설비의 점검 등 “포체인스”가 필요한 경우 또는 설비의 장애, 서비스
						이용의 폭주 등 불가항력 사항으로 인하여 서비스 이용에 지장이 있는
						경우 예외적으로 서비스 이용의 전부 또는 일부를 제한할 수 있습니다.
					</li>
					<li>
						서비스 제공에 필수적이면 “포체인스”는 정기점검을 시행할 수 있고 그
						일정은 서비스 웹사이트에 공지한 바에 따릅니다.
					</li>
					<li>
						“포체인스”가 제공하는 인프라 서비스를 이용하여 “고객”이 저장하는
						정보 또는 데이터는 고객이 소유 또는 관리하며 “포체인스”는 “고객”의
						동의 없이 이에 접근하지 않습니다.
					</li>
				</ul>

				<h5>제10조 (서비스 중단)</h5>
				<ul>
					<li>
						다음에 해당하는 경우 “포체인스”는 서비스 제공을 중단할 수 있습니다.
					</li>
					<ul class="color_red">
						<li>
							서비스 제공과 관련된 “포체인스”와 제 3자간의 계약 종료 및 해지,
							설비 수리 및 기타 유지보수 작업 등 불가피한 사유가 발생한 경우
						</li>
						<li>
							국가적 비상사태, 설비 결함 또는 서비스 이용 급증 등으로 정상적인
							서비스 운영에 장애가 발생할 위험이 있는 경우
						</li>
						<li>
							천재지변 등 불가피한 사유로 인해 “포체인스”가 실질적으로 안정적인
							서비스를 제공할 수 없는 경우
						</li>
						<li>
							전기통신 서비스 제공업체(전기통신사업법에 명시)가 통신 서비스를
							중단한 경우
						</li>
					</ul>
					<li>
						“포체인스”는 상기 제1항 제1호에 명시된 사항에 해당하는 경우 사전에
						“기업회원”이 지정한 통지 방법을 이용해 서비스 접속화면이나 서비스
						웹사이트상에 게재함으로써 서비스 중단을 공지합니다. 단, “포체인스”가
						통제할 수 없는 사유로 인한 서비스의 중단(“포체인스”의 고의, 과실이
						없는 설비 장애, 시스템 장애 등)으로 인하여 사전 통지가 불가능한
						경우에는 그러하지 아니합니다.
					</li>
					<li>
						“포체인스”는 상기 제1항 제2호, 제3호, 제4호에 명시된 사항에 대해서는
						사전 통지 없이 해당 서비스를 일시 중단할 수 있습니다.
					</li>
				</ul>
			</section>
			<!--content2 end-->
			<section class="content3">
				<h3>제 3장 이용요금 및 관리</h3>
				<h5>제11조 (서비스 이용요금 산정 및 정산)</h5>
				<ul>
					<li>
						“포체인스”는 이용요금 및 그 변경 사항을 서비스 웹사이트상에
						게시합니다.
					</li>
					<li>
						특별한 사유가 있지 않은 한 서비스 계약 기간 이용요금이 변경되더라도
						변경된 이용요금은 서비스 계약 기간 전체에 걸쳐 소급 적용되지
						않습니다.
					</li>
					<p class="color_red">
						※ “개인회원”의 경우 솔루션에 있어 구매 절차가 이루어지지 않으며
						“기업회원”으로 전환 시 사용이 가능합니다.
					</p>
				</ul>
			</section>
			<section class="content4">
				<h3>제 4장 계약당사자의 의무</h3>
				<h5>제12조 (포체인스의 의무)</h5>
				<ul>
					<li>
						“포체인스”는 안정적이고 지속적인 방식으로 “개인회원”이 요청하는
						서비스를 제공하기 위해 노력합니다.
					</li>
					<li>
						서비스의 정상 운영에 지장을 주는 장애가 발생하는 경우, “포체인스”는
						실행할 수 있는 최대한 빨리 정비 또는 복구하고 서비스를 안정적으로
						운영하는 데 최선을 다합니다.
					</li>
					<li>
						“포체인스”는 “개인회원”이 제기한 의견이나 불만을 공정하게 즉시 또는
						기간 내에 처리하며, “포체인스”가 정한 절차에 따릅니다.
					</li>
					<li>
						서비스를 원활히 운영하기 위하여 “포체인스”는 서비스 웹사이트에
						게시된 <span class="color_red">개인정보보호</span> 정책에 따라
						“개인회원” 개인정보를 수집하고 보관할 수 있습니다. “포체인스”는
						“개인회원”의 동의 없이 “개인회원”의 개인정보를 제 3자에게 제공하지
						않습니다. 단, 관련 법 및 규정에 따라 조사의 목적 등으로 법원 또는
						기타 사법 기관이 발행하는 영장 등을 통해 “개인회원” 개인정보 제공을
						요청받을 경우는 예외로 할 수 있습니다.
					</li>
					<li>
						“포체인스”는 이용약관에 벗어난 목적으로 서비스와 관련한 “개인회원”의
						정보에 접근하거나 데이터를 처리하지 않습니다. 다만 장애 처리,
						“개인회원”의 정보보호 등 원활한 서비스 제공을 위하여 접근이 필요한
						경우 “개인회원”의 정보에 접근, 내용을 파악할 수 있습니다.
					</li>
					<li>
						“포체인스”는 제5항에 따라 파악한 “고객”의 정보에 대하여 원활한
						서비스 제공을 위하여 삭제, 변경 등 데이터 처리가 필요한 경우
						“개인회원”의 동의를 받습니다. 다만, “개인회원”의 동의가 없거나
						동의를 하지 않더라도 “포체인스”의 서비스 운영 및 다른 “개인회원”의
						서비스 이용에 방해가 되는 경우 “포체인스”는 해당 “개인회원”의 서비스
						이용을 정지할 수 있으며, 그 절차는 제12조 제2항 이하에서 정한 바에
						따릅니다.
					</li>
				</ul>

				<h5>제13조 (고객의 의무)</h5>
				<ul>
					<li>
						개인회원”은 포체인스의 서비스 운영 또는 다른 “고객”의 서비스 이용을
						방해하거나 제3자의 권리를 침해해서는 안됩니다.
					</li>
					<li>
						“개인회원”은 서비스를 통해 운영하는 사이트 또는 게시판 등을 음란
						정보, 불법 정보, 유해정보, 불법도박 정보 등을 유통, 게재, 링크하기
						위한 목적으로 사용할 수 없으며, 법률상의 불법적인 행위를 할 수
						없습니다. 또한 “개인회원”이 서비스를 이용하여 취급하는 서비스 및
						정보 등 “고객”의 정보와 관련한 소유∙관리 등 일체의 책임은 “개인회원”
						본인에게 있습니다.
					</li>
					<li>
						서비스를 이용하여 제 3자의 개인정보를 처리, 관리, 이용 또는 이에
						접속하는 경우, “개인회원”은 관련 법 및 규정을 준수하여 해당
						개인정보를 관리하고 보호해야 하며, “포체인스”는 이와 같은 행위로
						인해 발생하는 제 3자의 정보유출 등을 포함하되 이에 한정되지 않는
						결과, 손실 또는 손해에 대하여 책임을 지지 않습니다.
					</li>
					<li>
						“개인회원”은 서비스를 통해 자신이 운영 중인 서버가 침입자로부터
						안전하게 보호받을 수 있도록 시스템 운영과 관련한 정기적인 보안
						업데이트를 해야 합니다. “고객”이 별도로 시스템 보안 서비스와 관련한
						계약을 “포체인스”와 체결한 경우를 제외하고는, “포체인스”는 발생한
						보안 사고에 대하여 책임을 지지 않습니다. “포체인스”는 “고객”의
						서비스 이용 관련 보안 조치를 위해 보안관제 업무를 수행할 수 있으며,
						이를 위해 “개인회원” 정보에 접근∙내용을 파악하여 그 결과를
						“개인회원”에게 통보하고 보안성 강화조치를 요구할 수 있습니다.
						“개인회원”은 “포체인스”의 보안성 강화 요청에 응해야 합니다.
					</li>
					<li>
						“개인회원”은 서비스를 통해 “개인회원”이 운영하는 서버, 소프트웨어
						프로그램 등에 대한 권리를 모두 보유 또는 획득해야 하며, 관련하여
						발생하는 저작권 문제 등에 대해 전적으로 책임을 져야 합니다.
					</li>
					<li>
						개인회원”이 서비스를 통해 불법 소프트웨어 또는 스팸 메일을 배포 또는
						발송하여 다른 “개인회원” 또는
						<span class="color_red">제 3자에게</span> 피해를 준 경우,
						“포체인스”는 이에 대한 책임이 없으며 해당 “개인회원”은 “포체인스”를
						면책시키고 의무, 손실, 손해배상, “포체인스”를 상대로 제기되는 소송
						등으로부터 ”포체인스“가 손해를 입지 않도록 해야 합니다.
					</li>
					<li>
						“개인회원”은 서비스, 여타 컴퓨터 코드, 파일, 또는 프로그램의
						안정적인 운영을 방해하거나 파괴시키는 소프트웨어 바이러스를 포함하는
						데이터를 게시 또는 전송해서는 안 됩니다.
					</li>
					<li>
						“개인회원”은 준거법, 약관, ”포체인스“의 서비스 웹사이트와 지침에
						명시되어 있는 지침과 예방조치 등을 완벽하게 숙지하고 체득하여 준수할
						의무가 있습니다. “개인회원”은 ”포체인스“의 업무에 방해되는 행위를
						하여서는 안됩니다.
					</li>
				</ul>
			</section>
			<section class="content5">
				<h3>제 5장 손해배상 등</h3>
				<h5>제14조 (포체인스의 손해배상)</h5>
				<ul>
					<li>
						"포체인스"는 관련 법령상 허용되는 한도 내에서 "FCTS (FOUR CHAINS
						TECHNOLOGY SERVICE)"와 관련하여 본 약관에 명시되지 않은 어떠한
						구체적인 사항에 대한 약정이나 보증을 하지 않습니다.
					</li>
					<li>
						"포체인스"는 "FCTS (FOUR CHAINS TECHNOLOGY SERVICE)" 이용과 관련하여
						"포체인스"의 고의 또는 과실로 인하여 "개인회원"이 손해를 입게 될
						경우 본 약관 및 관련 법령에 따라 "개인회원"에게 그 손해를
						배상합니다. 다만, "포체인스"는 "포체인스"의 과실 없이 발생한 아래와
						같은 손해에 대해서는 책임을 부담하지 않습니다. 또한 "포체인스"는
						법률상 허용 되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해,
						징계적 손해 및 징벌적 손해에 대한 책임을 부담하지 않습니다.
					</li>
					<ul class="color_red">
						<li>천재지변 또는 이에 따르는 불가항력의 상태에서 발생한 손해</li>
						<li>
							"개인회원"의 귀책 사유로 "FOUR-CHAINS 서비스" 이용에 장애가 발생한
							경우
						</li>
						<li>
							"FOUR-CHAINS 서비스"에 접속 또는 이용과정에서 발생하는 개인적인
							손해
						</li>
						<li>
							제 3자가 불법적으로 포체인스의 서버에 접속하거나 서버를
							이용함으로써 발생하는 손해
						</li>
						<li>
							제 3자가 "포체인스" 서버에 대한 전송 또는 "포체인스" 서버로부터의
							전송을 방해함으로써 발생하는 손해
						</li>
						<li>
							전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등
							제 3자가 서비스를 이용하는 과정에서 발생 된 손해
						</li>
						<li>
							기타 ”포체인스“의 고의 또는 과실이 없는 사유로 인해 발생한 손해
						</li>
					</ul>
					<li>
						"포체인스"는 "포체인스"의 과실이 없는 한 "개인회원" 상호 간 또는
						"개인회원"과 제 3자 상호 간에 "FCTS (FOUR CHAINS TECHNOLOGY
						SERVICE)"를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로
						인한 손해를 배상할 책임도 없습니다.
					</li>
					<li>
						개인회원”이 본 약관 또는 관련 법령을 위반하여 “포체인스”에 손해가
						발생한 경우 관련 법령이 규정하는 범위 내에서 “포체인스”에 그 손해를
						배상합니다.
					</li>
				</ul>

				<h5>제15조 (책임 제한)</h5>
				<ul>
					<li>
						“포체인스”는 “개인회원” "FCTS (FOUR CHAINS TECHNOLOGY SERVICE)"를
						통하여 제공한 게시물, 광고, 정보, 서비스 등에 대한 신뢰도, 정확성,
						적법성 등에 관하여는 책임을 지지 않습니다.
					</li>
					<li>
						"포체인스"는 유료로 제공되는 서비스 이용과 관련하여 관련 법령에
						특별한 규정이 없는 한 책임을 부담하지 않습니다.
					</li>
				</ul>

				<h5>제16조 (준거법 및 사법 담당)</h5>
				<ul>
					<li>
						"포체인스"와 "개인회원" 간 제기된 소송은 대한민국 법을 준거법으로
						합니다.
					</li>
					<li>
						"포체인스"와 "개인회원" 간 발생한 분쟁에 대한 소송은 민사소송법상의
						관할법원에 제소합니다.
					</li>
				</ul>
			</section>
		</div>
		<section class="content6">
			<h3 class="color_red">부칙</h3>
			<h3 class="font">본 약관은 회원가입일 부터 적용됩니다.</h3>
		</section>
	</div>
</template>

<script>
export default {};
</script>

<style scoped>
* {
	margin: 0 auto;
	padding: 0;
	font-family: 'Noto Sans KR', sans-serif;
}
#Back_color {
	background-color: #ffffff;
	height: 969px;
}
header {
	text-align: center;
	padding-top: 90px;
}
a {
	color: blue;
}
p {
	line-height: 1.4rem;
	font-size: 14px;
}

ul {
	font-size: 14px;
}

li {
	list-style: decimal inside;
	font-size: 14px;
	padding: 4px 0 4px 0;
	line-height: 1.4rem;
}

h5 {
	font-size: 15px;
	color: blue;
}
h1 {
	font-weight: 500;
	font-size: 2em;
}
h3,
h5 {
	padding: 15px 0 15px 0;
	font-weight: 500;
}
#container {
	width: 60%;
	height: 600px;
	margin-top: 40px;
	margin-bottom: 25px;
	border-top: solid 1px black;
	border-bottom: solid 1px black;
	background-color: #ffff;
	overflow-x: hidden;
	overflow-y: auto;
}
.content1,
.content2,
.content3,
.content4,
.content5 {
	width: 80%;
	margin-top: 20px;
	margin-bottom: 20px;
}

.color_red {
	color: red;
}
.content6 h3 {
	font-size: 18px;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 0;
}
.font {
	font-weight: 500;
}
#button_box {
	padding-top: 25px;
	text-align: center;
}
#button_box > form > input {
	width: 150px;
	height: 40px;
	cursor: pointer;
	background-color: #1388e5;
	border-radius: 10px;
	border: none;
	color: #ffff;
}
</style>
